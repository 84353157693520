import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const LatestLupusResearch = () => (
	<Layout>
		<SEO title="Latest lupus research" />
		<h1>Latest lupus research</h1>
		<h2>Lupus research in Western Australia</h2>
		<p>
			Links to the following websites will keep you abreast of research being
			undertaken within Western Australia.
		</p>
		<ul>
			<li>
				<a
					rel="noopener noreferrer"
					target="_blank"
					className="link"
					href="https://www.arthritiswa.org.au/research/current-research-activities/"
				>
					https://www.arthritiswa.org.au/research/current-research-activities/
				</a>
			</li>
			<li>
				<a
					rel="noopener noreferrer"
					target="_blank"
					className="link"
					href="https://research-repository.uwa.edu.au/en/persons/hans-nossent/publications/"
				>
					https://research-repository.uwa.edu.au/en/persons/hans-nossent/publications/
				</a>
			</li>
			<li>
				<a
					rel="noopener noreferrer"
					target="_blank"
					className="link"
					href="http://www.news.uwa.edu.au/201410227054/appointments/27m-target-poor-cousin-chronic-diseases"
				>
					http://www.news.uwa.edu.au/201410227054/appointments/27m-target-poor-cousin-chronic-diseases
				</a>
			</li>
		</ul>
		<h2>The Perth Lupus Registry</h2>
		<p>
			The Perth Lupus Registry is calling all diagnosed with lupus to sign onto
			the registry. The aim of the registry is to aid research about lupus in
			Western Australia and further Australia wide. Being on the Registry is
			completely voluntary and participants are able to withdraw at any time.
		</p>
		<p>
			The registry is observational so does not involve any medical intervention
			or affect you in any way. Therefore, providing consent provides us
			permission to access your patient file so we can observe your progress
			from your doctor visits, later asking for a once off small blood sample
			(60mls).
		</p>
		<h3>How to register</h3>
		<p>
			To obtain your Patient Information Sheet and Consent form contact the
			Perth Lupus Registry co-ordinator Michael Furfaro via email at{" "}
			<a className="link" href="mailto:michael.furfaro@uwa.edu.au">
				michael.furfaro@uwa.edu.au
			</a>{" "}
			or over the phone on{" "}
			<a href="tel:0861511062" className="link">
				(08) 6151 1062
			</a>{" "}
			or contact Arthritis & Osteoporosis WA at{" "}
			<a className="link" href="mailto:lupuswa@arthritiswa.org.au">
				lupuswa@arthritiswa.org.au
			</a>{" "}
			or on{" "}
			<a href="tel:0893882199" className="link">
				(08) 9388 2199
			</a>
			.
		</p>
		<h3>Clinical trials</h3>
		<p>To find out about current clinical trials visit:</p>
		<ul>
			<li>
				<a
					className="link"
					rel="noopener noreferrer"
					target="_blank"
					href="http://www.anzctr.org.au/"
				>
					http://www.anzctr.org.au/
				</a>
			</li>
			<li>
				<a
					className="link"
					rel="noopener noreferrer"
					target="_blank"
					href="https://clinicaltrials.gov/"
				>
					https://clinicaltrials.gov/
				</a>
			</li>
		</ul>
		<p>To find out about patient-reported outcomes measures visit:</p>
		<ul>
			<li>
				<a
					className="link"
					rel="noopener noreferrer"
					target="_blank"
					href="https://www.safetyandquality.gov.au/our-work/indicators/patient-reported-outcome-measures"
				>
					https://www.safetyandquality.gov.au/our-work/indicators/patient-reported-outcome-measures
				</a>
			</li>
		</ul>
		<h2>Lupus research worldwide</h2>
		<p>
			Links to the following websites will keep you abreast of research being
			undertaken further afield worldwide.
		</p>
		<ul>
			<li>
				<a
					className="link"
					rel="noopener noreferrer"
					target="_blank"
					href="https://www.lupus.org/research"
				>
					https://www.lupus.org/research
				</a>
			</li>
			<li>
				<a
					className="link"
					rel="noopener noreferrer"
					target="_blank"
					href="https://www.lrichicago.org/about-lupus-research-institute"
				>
					https://www.lrichicago.org/about-lupus-research-institute
				</a>
			</li>
			<li>
				<a
					className="link"
					rel="noopener noreferrer"
					target="_blank"
					href="http://lupusnephritis.org/"
				>
					http://lupusnephritis.org/
				</a>
			</li>
			<li>
				<a
					className="link"
					rel="noopener noreferrer"
					target="_blank"
					href="https://www.arthritisresearchuk.org/arthritis-information/conditions/lupus.aspx"
				>
					https://www.arthritisresearchuk.org/arthritis-information/conditions/lupus.aspx
				</a>
			</li>
			<li>
				<a
					className="link"
					rel="noopener noreferrer"
					target="_blank"
					href="https://www.lupuscanada.org/research-updates/"
				>
					https://www.lupuscanada.org/research-updates/
				</a>
			</li>
		</ul>
	</Layout>
)

export default LatestLupusResearch
